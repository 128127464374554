import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import mainImage from "assets/img/convention/about-us.jpg";
import ligthray from "assets/img/convention/ligthray.png";

import style from "./MainSectionStyle";

import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";

const useStyles = makeStyles(style);

export default function MainSection({ id, t }) {
  const classes = useStyles();
  return (
    <ContainerFullHeight id={id} bgColor="white">
      <div className={classes.container}>
        <GridContainer
          style={{ backgroundImage: `url("${mainImage}")` }}
          className={classes.titleContainer}
        >
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.titleTextContainer}
          >
            <h2 className={classes.title}>{t("main-title")}</h2>
          </GridItem>
        </GridContainer>
      </div>
      <div
        style={{ backgroundImage: `url("${ligthray}")` }}
        className={classes.lightray}
      ></div>
    </ContainerFullHeight>
  );
}
