import { PrimaryFontColorLanding } from "assets/jss/material-kit-pro-react";
import {
  container,
  titleLanding,
  title,
  subtitleLandingLight,
  PrimaryFontColor,
  cardTitle,
  subtitleLandingMedium,
} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h3, & h4, & h6": {
      color: PrimaryFontColorLanding,
    },
  },

  mainTitle: {
    ...titleLanding,
    textAlign: "center",
    marginBottom: "100px",
    marginTop: "100px",
    color: PrimaryFontColorLanding
  },
  pageHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "start",
    MsFlexAlign: "start",
    alignItems: "start",

    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },

  titleContainer: {
    backgroundPosition: "left",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    justifyContent: "flex-end",
  },
  img: {
    height: "500px",
    width: "100%",
    backgroundSize: "contain",
    zIndex: "1000",
    backgroundRepeat: "no-repeat",
  },

  subtitle: {
    ...subtitleLandingMedium,
    color: PrimaryFontColorLanding,
  },
  description: {
    ...subtitleLandingLight,
    color: PrimaryFontColorLanding,
  },
  text: {
    color: PrimaryFontColorLanding,
    fontSize: "20px",
  },
  imgContainer: {
    width: "100%",
  },
  title: {
    ...subtitleLandingMedium,
    color: PrimaryFontColorLanding,
  },
  cardTitle: {
    ...subtitleLandingMedium,
    "&, & a": { ...cardTitle["&, & a"],     ...subtitleLandingMedium, color: PrimaryFontColorLanding },
  },
  card4: {
    marginBottom: "60px",
    textAlign: "center",
  },
  audioContainer: {
    margin: "50px 0",
  },
});

export default style;
